.input-group {
    padding: 30px;
    border-radius: 20px;
    background-color: #e3e3e3;
    margin-bottom: 50px;

    .input {
        width: 100%;
        padding: 5px;
    }

    .controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:  center;
    }

    .explanation {
        font-size: 12px;
        padding: 10px 0px;
      }
}

.modal-center {
    text-align: center;
}

.content-area {
    text-align: left;
}

.sign-post {
    
    padding-bottom: 20px;

    h3 {
        font-size: 14px;
        text-align: left;
        width: 100%;
        padding-bottom: 10px;
    }

    .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .item {
            width: 160px;
            height: 160px;
            border: 2px solid rgba(0,0,0,.60);;
            border-radius: 10px;
        }
    }
}