.app-menu{
    list-style: none;
    font-weight: 400;
    font-size: 18px;
    padding: 0px;
    margin: 0px;

    li {
        display: inline;
        a {
            padding: 10px 30px;
            border-bottom: 4px solid white;

            &:hover {
                border-bottom: 4px solid #e24300;
                text-decoration: none;
            }
        }

    }
}