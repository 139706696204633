@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import url("https://use.typekit.net/zcg7ler.css");

:root {
  --amplify-primary-color: #e24300;
  --amplify-primary-tint: #e24300;
  --amplify-primary-shade: #e24300;
  --amplify-font-family: "depot-new-web","Open Sans", Helvetica, Arial, Sans-serif;
}


body {
  background: #333 !important;

  .hint {
    text-align: center;
    font-size: 12px;
    padding:0 70px;
    color: #FFF;
  }
}
.App {
  background-color: #fff;
  text-align: center;
  width: 100%;
  margin: auto;
  font-family: "depot-new-web","Open Sans", Helvetica, Arial, Sans-serif;
  font-size: 16px;
  a {
    color:#000;
  }

  a:hover{
    text-decoration: underline;
    color:#000;
  }

  .form-section-header {
    padding-top: 60px;
    background-color: red;
  }

  .app-actual {
    max-width: 700px;
    margin: auto;
  }



  .container-app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px;
    width: 100%;

    .sign-out-btn {
      padding-left: 10px;
    }
    .brand {
      width: 100%;
      text-align: left;
    }

    .details {
      width: 100%;
      text-align: right;
    }
  
  }
  .menu {
    text-align: right;
    padding: 0px;
    margin-top: -10px;
    margin-right: 25px;
  }
}

.content-area {
  padding-top: 30px;
}

.sign-in {
  height: 100vh;
  .holder {
    margin: auto;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%,-50%);
    text-align: center;
  }
}

.footer {
  color: #fff;
  background-color: #333;
  padding: 20px;
  font-size: 14px;
  margin-top: 20px;
  padding: 20px 20px 20px 20px;

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  .main {
    font-size: 18px;
  }
}