.Manager {

    margin-top: 20px;

    .search-box {
        padding:10px;
        background-color: #e24300;
        border-radius: 30px;
        padding-bottom: 20px;
        margin: 0px 20px;

        h3 {
            color:#fff;
            padding: 0px 0px 5px 0px;
            margin: 10px;
        }

        button {
            border: none;
            background-color: #fff;
            box-shadow: none;
            margin-left: 10px;
        }

        .super-search {
            min-width: 250px;
            margin-bottom: 20px;
        }
    }



}

.Dashboard {
    padding-top: 20px;
    min-height: 100px;

    .ui.loader {
        position: relative;
        display: block;
        transform: translateX(-50%);
        margin: 50px 0px;
    }
    
    .container {
        padding: 0px 20px 20px 20px;
    }
    .card {

        text-align: left;

        .card-mod {
            width: 100%;
        }

        .note {
            font-size: 14px;
            padding: 10px;
            text-align: center;
            border-radius: 10px;
        }

        .controls {
            padding: 10px;            
            button {
                margin-right: 0px;
                margin-left: 5px;
            
                &:first-child {
                    margin-right: 5px;
                    margin-left: -5px;
                }
            }
        }
    }
}

.overlay {
       margin: auto;
       width: 40%;
       min-width: 400px;
       top: 50%;
       right: 50%;
       transform: translate(50%,-50%);
}




